// Brunch automatically concatenates all files in your
// watched paths. Those paths can be configured at
// config.paths.watched in "brunch-config.js".
//
// However, those files will only be executed if
// explicitly imported. The only exception are files
// in vendor, which are never wrapped in imports and
// therefore are always executed.

// Import dependencies
//
// If you no longer want to use a dependency, remember
// to also remove its path from "config.paths.watched".
import "phoenix_html"
import "babel-polyfill"


import User                   from './resources/user'
import TicketType             from './resources/ticketType'
import Product                from './resources/product'
import DiscountRule           from './resources/discountRule'
import App                    from './resources/app'
import CourseEventParticipant from './resources/courseEventParticipant'
import EmailTemplate          from './resources/emailTemplate'
import Payments               from './resources/payments'
import Messages               from './resources/messages'
import PurchaseTickets        from './resources/purchaseTickets'
import select2                from "select2"
import IndexTableProperties   from './indexTableProperties'
import Email                  from './resources/email'
import dateFilter             from './filters/dateFilter';
import UserComment            from './resources/userComment'
import Membership             from './resources/membership'
import TimeslotGroup          from './resources/timeslotGroup'
import purchaseApp from "./resources/purchaseApp"
import walletPassDataEditorApp from "./resources/walletPassDataEditorApp"
import Sidebar from "./resources/sidebar"
import MembershipType         from './resources/membershipType'

dateFilter.init()

select2($)
// import equalWidth from "./equalWidth";
// import moreFilters from "./filters/moreFilters";
//import contentHandler from './contentHandler'

// Import local files
//
// Local files can be imported directly using relative
// paths "./socket" or full ones "web/static/js/socket".

// import socket from "./socket"





$(document).ready(function () {
  $('select.select2').select2()
  document.getElementsByClassName("sidebar") && Sidebar.init()
  document.getElementById("userTabs") && User.init()
  document.getElementById("app-form") && App.initForm()
  document.getElementById("ticket_type-form") && TicketType.initForm()
  document.getElementById("product-form") && Product.initForm()
  document.getElementById("discount_rule-form") && DiscountRule.initForm()
  document.getElementById('membership_type-form') && MembershipType.initForm()
  document.getElementById('course_event_participant-index') && CourseEventParticipant.init()
  document.getElementById('course_event_reserve-index') && CourseEventParticipant.init()
  document.getElementById('email_template-index') && EmailTemplate.init()
  document.getElementsByClassName('index-table').length > 0 && IndexTableProperties.init()
  document.getElementById('payments-table') && Payments.init()
  document.getElementById('messages-table') && Messages.init()
  document.getElementById('purchase-tickets') && PurchaseTickets.init()
  document.getElementById("import-emails-form") && Email.initImportForm()
  document.getElementById("timeslot_group-form") && TimeslotGroup.initForm()
  document.getElementsByClassName('user-comments-tab').length > 0 && UserComment.init()
  document.getElementsByClassName("membership-controls") && Membership.initForm()
  purchaseApp.initForm()
  walletPassDataEditorApp.initForm()
});