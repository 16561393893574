export default {
  initForm() {
    this.handleRestriction()
    this.initFieldControls()
    $('#membership_type-form').on('change', '#membership_type_restricted', this.handleRestriction)
  },

  handleRestriction() {
    let $restricted = $('#membership_type_restricted')
    let $formGroup = $('#membership_type_apps').parents('.form-group')
    let $checkboxes = $formGroup.find('input[type="checkbox"]')
    if ($restricted.is(':checked')) {
      $formGroup.show()
      $checkboxes.prop('disabled', false)
    } else {
      $formGroup.hide()
      $checkboxes.prop('disabled', true)
      $checkboxes.prop('checked', false)
    }
  },

  initFieldControls() {
    const $requiredFieldsSelect = $('#membership_type_required_fields.select2');
    const $optionalFieldsSelect = $('#membership_type_optional_fields.select2');

    $requiredFieldsSelect.find('option:selected').each(function () {
      $optionalFieldsSelect.find(`option[value=${this.value}]`).attr('disabled', true)
    })
    $optionalFieldsSelect.find('option:selected').each(function () {
      $requiredFieldsSelect.find(`option[value=${this.value}]`).attr('disabled', true)
    })
    $requiredFieldsSelect.on('select2:select', function (e) {
      $optionalFieldsSelect.find(`option[value=${e.params.data.id}]`).attr('disabled', true)
    })
    $requiredFieldsSelect.on('select2:unselect', function (e) {
      $optionalFieldsSelect.find(`option[value=${e.params.data.id}]`).removeAttr('disabled')
    })
    $optionalFieldsSelect.on('select2:select', function (e) {
      $requiredFieldsSelect.find(`option[value=${e.params.data.id}]`).attr('disabled', true)
    })
    $optionalFieldsSelect.on('select2:unselect', function (e) {
      $requiredFieldsSelect.find(`option[value=${e.params.data.id}]`).removeAttr('disabled')
    })

  }
}